import { IPagination } from "@/models/Pagination";
import {
  ITenantUpdateForm,
  ITenantUser,
  ITenantUserUpdate,
} from "@/models/Tenant";
import { ITenantUserDTO } from "@/models/TenantUser";
import { IUserVerificationDTO } from "@/models/User";
import { ErrorResponse } from "@/services/axios/error";
import { TenantAPI } from "@/services/tenant";
import { UserAPI } from "@/services/user";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { IResponse } from "@/services/axios/entities";

const name = "TenantUserModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class TenantUserModule extends VuexModule {
  private errorCreateUser = {} as ErrorResponse;
  private errorVerificationUser = {} as ErrorResponse;

  private dataGetListTenantUser = [] as ITenantUserDTO[];
  private dataGetTenantUser = {} as ITenantUserDTO;
  private errorDataGetListTenantUser = {} as ErrorResponse;
  private detailTenantUser = {} as ITenantUpdateForm;
  private errorUpdateUser = {} as ErrorResponse;
  private pagination = {} as IPagination;
  private errorDataTenantUser = {} as ErrorResponse;
  private updateTenantPasswordUser = {} as ITenantUpdateForm;
  private errorUpdateTenantPasswordUser = {} as ErrorResponse;
  private sendActivationEmailError = {} as ErrorResponse;

  private isLoadingListTenantUser = false;
  get hasCreateUserErrorMsg() {
    return !isEmpty(this.errorCreateUser);
  }
  get errorVerificationUserGetter() {
    return this.errorVerificationUser;
  }
  get isLoadingListTenantUserGetter() {
    return this.isLoadingListTenantUser;
  }

  get dataTenantUserGetter() {
    return this.dataGetTenantUser;
  }

  get dataListTenantUserGetter() {
    return this.dataGetListTenantUser;
  }
  @Mutation
  createUserSuccess() {
    ElMessage.success("Create new User success.");
    this.errorCreateUser = {} as ErrorResponse;
  }
  @Mutation
  createUserFailure(error: ErrorResponse) {
    ElMessage.error("Create new User fail.");
    this.errorCreateUser = error;
  }
  @Action
  async createUser(payload: ITenantUser) {
    const servicesTenantAPI = new TenantAPI();
    const { error } = await servicesTenantAPI.createUser(
      payload.TenantId,
      payload
    );

    if (error) {
      this.createUserFailure(error);
    } else {
      this.createUserSuccess();
    }
  }

  @Mutation
  verificationUserSuccess() {
    this.errorVerificationUser = {} as ErrorResponse;
  }
  @Mutation
  verificationUserFailure(error: ErrorResponse) {
    this.errorVerificationUser = error.message;
  }
  @Action
  async verificationUser(payload: IUserVerificationDTO) {
    const servicesUserAPI = new UserAPI();
    const { error } = await servicesUserAPI.userVerification(payload);

    if (error) {
      this.verificationUserFailure(error);
      return error.message;
    } else {
      this.verificationUserSuccess();
      return "";
    }
  }

  @Mutation
  setLoadingListTenantUser(loading: boolean) {
    this.dataGetListTenantUser = [] as ITenantUserDTO[];
    this.isLoadingListTenantUser = loading;
  }
  @Mutation
  getListTenantUserSuccess(data: any) {
    this.errorDataGetListTenantUser = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenantUser = Collection;
    this.pagination = rest;
    this.isLoadingListTenantUser = false;
  }
  @Mutation
  getListTenantUserFailure(error: ErrorResponse) {
    this.errorDataGetListTenantUser = error;
    this.isLoadingListTenantUser = false;
  }
  @Action
  async getListTenantUser(payload: any) {
    this.setLoadingListTenantUser(true);
    const servicesTenantAPI = new TenantAPI();
    const { data, error } = await servicesTenantAPI.getListTenantUser(
      payload.TenantId,
      payload.params
    );

    if (error) {
      this.getListTenantUserFailure(error);
    } else {
      this.getListTenantUserSuccess(data);
    }
  }

  @Mutation
  updateUserSuccess(data: any) {
    ElMessage.success("Update User success.");
    this.errorUpdateUser = {} as ErrorResponse;
    this.detailTenantUser = data;
  }
  @Mutation
  updateUserFailure(error: ErrorResponse) {
    this.errorUpdateUser = error;
  }
  @Action
  async updateUser(payload: ITenantUserUpdate) {
    const servicesTenantAPI = new TenantAPI();
    const { data, error } = await servicesTenantAPI.updateUser(
      payload.TenantId,
      payload.UserId,
      payload
    );

    if (error) {
      this.updateUserFailure(error);
    } else {
      await this.getTenantUser({
        TenantId: payload.TenantId,
        UserId: payload.UserId,
      });
      this.updateUserSuccess(data);
    }
  }

  @Mutation
  loadingTenantUser() {
    this.dataGetTenantUser = {} as ITenantUserDTO;
  }
  @Mutation
  getTenantUserSuccess(data: ITenantUserDTO) {
    this.errorDataGetListTenantUser = {} as ErrorResponse;
    this.dataGetTenantUser = data;
  }
  @Mutation
  getTenantUserFailure(error: ErrorResponse) {
    this.errorDataTenantUser = error;
  }

  @Mutation
  resetTenantUserEmpty() {
    this.dataGetTenantUser = {} as ITenantUserDTO;
  }
  @Action
  async getTenantUser(payload: {
    TenantId: string;
    UserId: string;
  }): Promise<IResponse> {
    const { TenantId, UserId } = payload;
    this.loadingTenantUser();
    const servicesTenantAPI = new TenantAPI();
    const { data, error } = await servicesTenantAPI.getTenantUser(
      TenantId,
      UserId
    );

    if (error) {
      this.getTenantUserFailure(error);
      return { error };
    } else {
      this.getTenantUserSuccess(data);
      return { data };
    }
  }

  @Mutation
  updatePassTenantUserSuccess(data: any) {
    ElMessage.success("Update Password Tenant User success.");
    this.errorUpdateTenantPasswordUser = {} as ErrorResponse;
    this.updateTenantPasswordUser = data;
  }
  @Mutation
  updatePassTenantUserFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorUpdateTenantPasswordUser = error;
  }
  @Action
  async updatePassTenantUser(payload: any) {
    const servicesTenantAPI = new TenantAPI();
    const { data, error } = await servicesTenantAPI.updatePassTenantUser(
      payload.TenantId,
      payload.UserId,
      payload
    );

    if (error) {
      this.updatePassTenantUserFailure(error);
    } else {
      this.updatePassTenantUserSuccess(data);
    }
  }

  @Mutation
  sendActivationEmailFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.sendActivationEmailError = error;
  }
  @Action
  async sendActivationEmail(payload: { UserId: string; TenantId: string }) {
    const { UserId, TenantId } = payload;
    this.loadingTenantUser();
    const servicesTenantUserAPI = new TenantAPI();
    const { error } = await servicesTenantUserAPI.sendActivationEmailTenantUser(
      TenantId,
      UserId
    );
    if (error) {
      this.sendActivationEmailFailure(error);
      return { error };
    }
    this.getListTenantUser({});
  }
}
export default getModule(TenantUserModule);
